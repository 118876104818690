import { VIEW_PERMISSION, MAINTAIN_PERMISSION, FILTERS_EQUAL, GROWERS_UPLOADINTAKES } from "@kraftheinz/common";
import { e as INTAKE_STATUS_AVAILABLE_FOR_UPLOAD, I as INTAKE_STATUS_COLORS, a as INTAKE_STATUS_TEXTS } from "./intake.status.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Upload Intakes",
      "has-active": false,
      "search-by": "IntakeNumber,CropCode",
      "ph-search-by": "Search by Intake, Crop",
      "selected-row-keys": _vm.hasMaintainPermission && _vm.selectedRowKeys,
      "on-select-change": _vm.onSelectChange
    },
    scopedSlots: _vm._u([_vm.hasMaintainPermission ? {
      key: "create-buttons",
      fn: function() {
        return [_c("a-button", {
          attrs: {
            "disabled": !_vm.selectedRowKeys.length,
            "loading": _vm.isUploading,
            "type": "primary"
          },
          on: {
            "click": _vm.onUploadClick
          }
        }, [_vm._v(" Upload Intakes "), _vm.selectedRowKeys.length ? _c("span", {
          staticClass: "ml-1"
        }, [_vm._v("(" + _vm._s(_vm.selectedRowKeys.length) + ")")]) : _vm._e()])];
      },
      proxy: true
    } : null, _vm.hasViewPermission ? {
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record;
        return [_c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Detail")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "right-circle",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1)];
      }
    } : null], null, true)
  }, [_c("url-field", {
    key: "IntakeNumber",
    attrs: {
      "data-index": "intakeNumber",
      "title": "Intake Code",
      "base-url": "/watties-grower/upload-intakes"
    }
  }), _c("text-field", {
    key: "TruckCode",
    attrs: {
      "data-index": "truckCode",
      "title": "Truck"
    }
  }), _c("text-field", {
    key: "GrowerCode",
    attrs: {
      "data-index": "growerCode",
      "title": "Grower",
      "parse": _vm.parseGrowerCode
    }
  }), _c("text-field", {
    key: "ContractCode",
    attrs: {
      "data-index": "contractCode",
      "title": "Contract"
    }
  }), _c("text-field", {
    key: "CropCode",
    attrs: {
      "data-index": "cropCode",
      "title": "Crop Code",
      "parse": _vm.parseCropCode
    }
  }), _c("text-field", {
    key: "TimeIn",
    attrs: {
      "data-index": "timeIn",
      "title": "Time In",
      "parse": _vm.parseTimeIn
    }
  }), _c("tag-field", {
    key: "Status",
    attrs: {
      "data-index": "status",
      "title": "Status",
      "color": _vm.getStatusColor,
      "parse": _vm.parseStatus,
      "sorter": true
    }
  })], 1);
};
var staticRenderFns$1 = [];
const FIELD_STATUS = "Status";
const __vue2_script$1 = {
  name: "ListUploadIntake",
  inject: ["apiUrl", "crud", "can"],
  data() {
    return {
      isUploading: false,
      selectedRowKeys: [],
      permissions: {
        view: VIEW_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  computed: {
    intakes() {
      return this.crud.getList();
    },
    hasViewPermission() {
      return this.can(this.permissions.view);
    },
    hasMaintainPermission() {
      return this.can(this.permissions.maintain);
    }
  },
  created() {
    this.crud.setFilter(FIELD_STATUS, {
      operator: FILTERS_EQUAL,
      value: INTAKE_STATUS_AVAILABLE_FOR_UPLOAD
    });
  },
  methods: {
    getStatusColor(value) {
      return INTAKE_STATUS_COLORS[value];
    },
    parseCropCode(value, record) {
      if (!value)
        return "";
      return `${value} ${record.cropName}`;
    },
    parseGrowerCode(value, record) {
      if (!value)
        return "";
      return `${value} - ${record.growerName}`;
    },
    parseStatus(value) {
      return INTAKE_STATUS_TEXTS[value];
    },
    parseTimeIn(value) {
      if (!value)
        return "";
      return this.$moment(value).format("D/M/Y hh:mm A");
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async onUploadClick() {
      this.isUploading = true;
      const selectedIntakeNumbers = this.intakes.filter((intake) => this.selectedRowKeys.includes(intake.id)).map((intake) => intake.intakeNumber);
      this.crud.updateEntity("editForm", {
        resourceKey: "intakeNumbers",
        value: selectedIntakeNumbers
      });
      try {
        await this.crud.submitEntity("replace", `${this.apiUrl}/grower/intakes/upload`);
        await this.crud.fetchList();
        this.selectedRowKeys = [];
      } finally {
        this.isUploading = false;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListUploadIntake = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-upload-intake"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.intakes.upload",
      "api-url": _vm.apiUrl,
      "create-route": "/watties-grower/upload-intakes",
      "edit-route": "/watties-grower/upload-intakes/:id",
      "page": _vm.page
    }
  }, [_c("list-upload-intake")], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    ListUploadIntake
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      page: GROWERS_UPLOADINTAKES,
      menuItems: [
        {
          key: "intakeprocessing",
          title: "Intake Processing",
          path: ""
        },
        {
          key: "upload-intakes",
          title: "Upload Intakes",
          path: "/watties-grower/upload-intakes"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
