const INTAKE_STATUS_INCOMPLETE = "I";
const INTAKE_STATUS_ENTERED = "E";
const INTAKE_STATUS_SAMPLE_RECORDED = "S";
const INTAKE_STATUS_AVAILABLE_FOR_UPLOAD = "A";
const INTAKE_STATUS_READY_FOR_UPLOAD = "U";
const INTAKE_STATUS_CANCELLED = "C";
const INTAKE_STATUS_REJECTED = "R";
const INTAKE_STATUS_COLORS = {
  [INTAKE_STATUS_INCOMPLETE]: "blue",
  [INTAKE_STATUS_ENTERED]: "pink",
  [INTAKE_STATUS_SAMPLE_RECORDED]: "orange",
  [INTAKE_STATUS_AVAILABLE_FOR_UPLOAD]: "cyan",
  [INTAKE_STATUS_READY_FOR_UPLOAD]: "purple",
  [INTAKE_STATUS_CANCELLED]: "gray",
  [INTAKE_STATUS_REJECTED]: "red"
};
const INTAKE_STATUS_TEXTS = {
  [INTAKE_STATUS_INCOMPLETE]: "Incomplete",
  [INTAKE_STATUS_ENTERED]: "Entered & Waiting",
  [INTAKE_STATUS_SAMPLE_RECORDED]: "Sample Record",
  [INTAKE_STATUS_AVAILABLE_FOR_UPLOAD]: "Available",
  [INTAKE_STATUS_READY_FOR_UPLOAD]: "Unavailable",
  [INTAKE_STATUS_CANCELLED]: "Cancelled Intake",
  [INTAKE_STATUS_REJECTED]: "Rejected Intake"
};
export { INTAKE_STATUS_COLORS as I, INTAKE_STATUS_TEXTS as a, INTAKE_STATUS_INCOMPLETE as b, INTAKE_STATUS_ENTERED as c, INTAKE_STATUS_SAMPLE_RECORDED as d, INTAKE_STATUS_AVAILABLE_FOR_UPLOAD as e, INTAKE_STATUS_READY_FOR_UPLOAD as f, INTAKE_STATUS_CANCELLED as g, INTAKE_STATUS_REJECTED as h };
